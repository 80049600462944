
// 验证规则
const rolesRules = {
  // 角色名称
  name: [
    { required: true, message: '请输入角色名称', trigger: 'blur' },
    { min: 2, max: 8, message: '长度在 2 到 8 个字符', trigger: 'blur' }
  ],
  // 角色描述
  roleDesc: [
    { required: true, message: '请输入角色描述', trigger: 'blur' },
    { min: 2, max: 15, message: '长度在 2 到 15 个字符', trigger: 'blur' }
  ],
  // 角色描述
  desc: [
    { required: true, message: '请输入角色描述', trigger: 'blur' },
    { min: 2, max: 15, message: '长度在 2 到 15 个字符', trigger: 'blur' }
  ],
  // 权限标识
  tag: [
    { required: true, message: '请输入角色描述', trigger: 'blur' },
    { min: 2, max: 15, message: '长度在 2 到 15 个字符', trigger: 'blur' }
  ],
  parent: [{ required: true, message: '请选择权限归属', trigger: 'change' }],

}

export { rolesRules }
