<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>权限管理</el-breadcrumb-item>
      <el-breadcrumb-item>权限分配</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
         <el-table :data="renlist"  style="width: 100%">
      <el-table-column type="index"></el-table-column>
      <el-table-column label="角色名称" prop="name"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <!-- <el-button icon="el-icon-edit" type="primary" @click="finRoles(scope.row.id)">编辑</el-button>
          <el-button icon="el-icon-delete" type="danger" @click="deleRolesUser(scope.row.id)">删除</el-button> -->
          <el-button icon="el-icon-s-tools" type="warning" @click="showSetRighDialog(scope.row)">分配权限</el-button>
        </template>
      </el-table-column>
    </el-table>
    </el-card>
  </div>
</template>

<script>
import { Getren } from '../../../api/shouhuo'
export default {
  data() {
    return {
      renlist: []
    }
  },
  mounted() {
    this.Getrenlist()
  },
  methods: {
    async Getrenlist() {
      const { data } = await Getren()
      this.renlist = data.data
      console.log(this.renlist)
    }
  }
}
</script>

<style>
</style>