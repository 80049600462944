// 民族
const nations = [
  '汉族',
  '蒙古族',
  '回族',
  '藏族',
  '维吾尔族',
  '苗族',
  '彝族',
  '壮族',
  '布依族',
  '朝鲜族',
  '满族',
  '侗族',
  '瑶族',
  '白族',
  '土家族',
  '哈尼族',
  '哈萨克族',
  '傣族',
  '黎族',
  '傈僳族',
  '佤族',
  '畲族',
  '高山族',
  '拉祜族',
  '水族',
  '东乡族',
  '纳西族',
  '景颇族',
  '柯尔克孜族',
  '土族',
  '达斡尔族',
  '仫佬族',
  '羌族',
  '布朗族',
  '撒拉族',
  '毛南族',
  '仡佬族',
  '锡伯族',
  '阿昌族',
  '普米族',
  '塔吉克族',
  '怒族',
  '乌孜别克族',
  '俄罗斯族',
  '鄂温克族',
  '德昂族',
  '保安族',
  '裕固族',
  '京族',
  '塔塔尔族',
  '独龙族',
  '鄂伦春族',
  '赫哲族',
  '门巴族',
  '珞巴族',
  '基诺族'
]
// 驾照类型
const licenses = ['无', 'A1', 'A2', 'A3', 'B1', 'B2', 'C1', 'C2', 'C3', 'C4', 'D', 'E', 'F', 'M', 'N', 'P']
// 衣服码
const designations = ['160/S', '165/M', '170/L', '175/XL', '180/XXL', '185/XXXL', '190/XXXXL']
// 鞋码
const footwears = ['35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46']
// 户口性质
const household = ['本省城镇', '本省农业', '外省城镇', '外省农业', '本市农业', '本市城镇']
// 结婚
const marriages = ['未婚', '已婚', '离异']
// 学历
const educations = ['小学', '初中', '高中/高职', '大专', '本科', '硕士', '博士']
// 关系
const relationship = ['父亲', '母亲', '儿子', '女儿', '配偶', '兄弟', '姐妹']
// 转换时间选择
const transformation = data => {
  if (data.date) {
    return (data.date = data.date.join('至'))
  }
}
const orglist = ['群众', '中共党员', '中共预备党员']
// 在职状态
const manstatus = ['试用期', '正式', '实习']

export { nations, licenses, designations, footwears, household, marriages, educations, transformation, relationship, orglist, manstatus }
