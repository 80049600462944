
// 金额转换为分
export const exchangefen = nums => {
  return nums * 100
}

// 获取金额转换为元
export const exchangeyuan = nums => {
  return nums / 100
  //  num = num.toFixed(2)输出结果为 2.45
}
