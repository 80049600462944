<template>
  <div>
    <el-pagination
      layout="total, sizes, prev, pager, next"
      :total="pageData.count"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageData.size"
      :current-page="pageData.page"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
export default {
  name: 'Fenye',
  props: ['pageData'],
  data() {
    return {

    }
  },
  methods: {
    // 分页代码块
    // 监听pagingtion改变
    handleSizeChange(newSize) {
      this.$emit('newsize', newSize)
    },
    // 监听页码值改变事件
    handleCurrentChange(newPape) {
      this.$emit('newpape', newPape)
    }
  }
}
</script>

<style scoped>
.el-pagination{
  margin-top: 20px;
}
</style>
<!--使用方法-->
<!--import Fenye from '@/components/Fenye'-->
<!--      分页-->
<!--<Fenye :page-data="pageData" @newsize="bianzhi" @newpape="bianye" />-->


