<template>
  <div class="vehicle_container">
车辆
  </div>
</template>
<script>

export default {
  data() {
    return {

    }
  },

  methods: {


  }
}
</script>
<style lang="less" scoped>

</style>
