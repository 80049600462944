
// 存储Local
export const steLocal = (localName, data) => {
  localStorage.setItem(localName, data)
}

// 获取Local
export const getLocal = localName => {
  return localStorage.getItem(localName)
}
