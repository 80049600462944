import axios from './index'
import store from '../store'

export const loginAp = data => {
  return axios.request({
    url: '/user/signin',
    method: 'post',
    data
  })
}

// 获取用户权限
export const fetchPermission = () => {
  return axios.request({
    url: '/permission?user=' + store.state.login.UserToken,
    method: 'get'
  })
}
// 获取注册的key
export const GetKey = () => {
  return axios.request({
    url: '/user/reg/key',
    method: 'post'
  })
}
// 搜索关键字匹配用户
export const Setuser = data => {
  return axios.request({
    url: '/user/key/search',
    method: 'post',
    data
  })
}
// 搜索关键字匹配用户sass
export const Serusersass = data => {
  return axios.request({
    url: '/sass/user/search_list',
    method: 'post',
    data
  })
}
// 获取项目列表
export const Setpronodelist = data => {
  return axios.request({
    url: '/sass/pronode/list',
    method: 'post',
    data
  })
}

// 判断token是否过期
// export const authorization = () => {
//   return axios.request({
//     url: '/users/authorization',
//     method: 'get'
//   })
// }
