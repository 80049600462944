import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routers'
import store from '../store/index'
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
  //s
}
Vue.use(VueRouter)

const router = new VueRouter({
  // mode:"history",去除哈希值的#号
  routes
})

// 改造权限认证 使用vuex
router.beforeEach((to, from, next) => {
  const token = store.state.login.UserToken
  // console.log(token);
  // console.log(to,from);
  if (!token) {
    // 不需要鉴权的直接放行
    if (to.name === 'Register' || to.name === 'Information' || to.name === 'Login') next()
    else next({ name: 'Login' })
    // next()
  } else {
    next()
  }
})
       
export default router
