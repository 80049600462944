<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>

      <el-breadcrumb-item>组织列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-button type="primary" @click="addRolesName()">添加组织</el-button>
    </el-card>
    <!-- 表格 -->
    <el-table :data="tableData" stripe style="width: 100%">
      <el-table-column type="index"></el-table-column>
      <el-table-column label="组织名称" prop="name"></el-table-column>
      <el-table-column label="组织描述" prop="roleDesc"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button icon="el-icon-edit" type="primary" @click="finOrganization(scope.row.id)">编辑</el-button>
          <el-button icon="el-icon-delete" type="danger" @click="deleRolesUser(scope.row.id)">删除</el-button>
          <el-button icon="el-icon-s-tools" type="warning" @click="showSetRighDialog(scope.row)">分配角色</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 添加角色对话框 -->
    <el-dialog title="添加组织" :visible.sync="dialogVisible" width="50%" @close="addRolesClose">

      <el-form :model="addeForm" :rules="rolesRules" ref="addeFormRef" label-width="100px" class="demo-ruleForm">
        <el-form-item label="组织名称" prop="name">
          <el-input v-model="addeForm.name"></el-input>
        </el-form-item>
        <el-form-item label="组织描述" prop="roleDesc">
          <el-input v-model="addeForm.roleDesc"></el-input>
        </el-form-item>
        <el-form-item label="组织上级">
          <el-cascader
            v-model="addeForm.parent_id"
            :options="parentoptions"
            :props="optionProps"
            :show-all-levels="false"

            @change="handleChange"></el-cascader>
          <span>不选择为顶级组织</span>
        </el-form-item>
        <el-form-item label="组织负责人">
          <el-select v-model="addeForm.charger" filterable placeholder="请选择">
            <el-option
              v-for="item in chargerOptions"
              :key="item.user_id"
              :label="item.user_name"
              :value="item.user_id">
<!--              <img :src="item.user_id" style="height:32px;float: right">-->
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="组织类型">
          <el-radio-group v-model="addeForm.type">
            <el-radio label="1">总公司</el-radio>
            <el-radio label="2">分公司</el-radio>
            <el-radio label="3">项目</el-radio>
            <el-radio label="99">其他</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="addOrganization">确 定</el-button>
  </span>
    </el-dialog>
    <!-- 修改角色对话框 -->
    <el-dialog title="修改组织" :visible.sync="editlogVisible" width="50%" @close="addRolesClose">

      <el-form :model="editTissueForm" :rules="rolesRules" ref="addeFormRef" label-width="100px" class="demo-ruleForm">
        <el-form-item label="组织名称" prop="name">
          <el-input v-model="editTissueForm.name"></el-input>
        </el-form-item>
        <el-form-item label="组织描述" prop="roleDesc">
          <el-input v-model="editTissueForm.roleDesc"></el-input>
        </el-form-item>
        <el-form-item label="组织上级">
          <el-cascader
            v-model="editTissueForm.parent_id"
            :options="parentoptions"
            :props="optionProps"
            :show-all-levels="false"

            @change="eidthandleChange"></el-cascader>
          <span>不选择为顶级组织</span>
        </el-form-item>
        <el-form-item label="组织负责人">
          <el-select v-model="editTissueForm.charger" filterable placeholder="请选择">
            <el-option
              v-for="item in chargerOptions"
              :key="item.user_id"
              :label="item.user_name"
              :value="item.user_id">
              <!--              <img :src="item.user_id" style="height:32px;float: right">-->
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="组织类型">
          <el-radio-group v-model="editTissueForm.type">
            <el-radio label="1">总公司</el-radio>
            <el-radio label="2">分公司</el-radio>
            <el-radio label="3">项目</el-radio>
            <el-radio label="99">其他</el-radio>
          </el-radio-group>
        </el-form-item>

      </el-form>

      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="editRoles">确 定</el-button>
  </span>
    </el-dialog>
    <!-- 分配权限对话框 -->
    <el-dialog
      title="分配权限"
      :visible.sync="setRighDialogVisible"
      width="50%"
      @close="setRightDialogClosed">

      级联选择角色
      <el-cascader
v-model="tissue.role_id"
        :options="roleOptions"
                   :props="roleProps"
                   :show-all-levels="false"
                   @change="roleChange"></el-cascader>
<!--角色列表-->
      <el-table
        :data="roteListeData"
        ref="roltipleTable"
        stripe
        @select="handleSelectionChange"
        @select-all="checAall"
        style="width: 100%">
        <el-table-column
          type="selection"
          width="55">
        </el-table-column>
        <el-table-column type="index"></el-table-column>
        <el-table-column
          prop="name"
          label="角色名"
          width="180">
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button icon="el-icon-s-tools" type="warning" @click="roteListSetRighDialog(scope)">修改权限</el-button>
          </template>
        </el-table-column>
      </el-table>

      <span slot="footer" class="dialog-footer">
    <el-button @click="setRighDialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="allotRights">确 定</el-button>
  </span>
    </el-dialog>
    <!-- 重新分配权限对话框 -->
    <el-dialog
      title="分配权限"
      :visible.sync="fenpeiVisible"
      width="50%"
      @close="endiRightDialogClosed">

<!--            树形控件-->
            <el-tree
              ref='terrRef'
              :data="rightlist"
              :props="treeProps"
              show-checkbox
              node-key='id'
              default-expand-all
              :default-checked-keys="defKeys" >

            </el-tree>
      <span slot="footer" class="dialog-footer">
    <el-button @click="quxiaoxiugaiquanxian">取 消</el-button>
    <el-button type="primary" @click="reassignment">确 定</el-button>
  </span>
    </el-dialog>

  </div>
</template>
<script>
// 获取员工列表
import { GetPersonnelList } from '@/api/personnel'
// 获取组织链式关系
import { GetAssociate, AddAssociate, EditAssociate, OneAssociate, DelAssociate, AddDistributionRole } from '@/api/organization'
// eslint-disable-next-line no-unused-vars
import { GetPermission, AddDispatch, GetLimits, GetParent, GetRoleone } from '@/api/personnel'
// 验证规则
import { rolesRules } from '@/utils/roleRules'

export default {
  data() {
    return {
      // 添加组织数据
      addeForm: {
        name: '', // 组织名称
        roleDesc: '', // 组织描述
        parent_id: 0, // 上级id
        type:'1', // 组织类别
        charger: ''// 负责人
      },
      // 组织负责人
      chargerOptions: [],
      // 验证规则
      rolesRules,
      // 获取组织列表数据
      parentoptions: [],
      // 修改展示内容
      optionProps: {
        value: 'id',
        label: 'name',
        children: 'childs',
        checkStrictly: true
      },
      // 初始显示
      dialogVisible: false,
      // 修改的数据
      editTissueForm: {},
      // 修改窗口
      editlogVisible: false,
      // 获取权限列表数据
      rightlist: [],
      // 树形控件属性绑定
      treeProps: {
        label: 'name',
        children: 'childs'

      },
      // 分配角色是的级联选择
      roleOptions: [],
      // 修改分配角色展示内容
      roleProps: {
        value: 'id',
        label: 'name',
        children: 'childs',
        checkStrictly: true
      },
      // 控制分配权限对话框
      setRighDialogVisible: false,
      // 默认选中得节点
      defKeys: [],
      // 默认展示的得节点
      defKeysitem: [],
      // 即将分配组织的id
      tissue: {
        id: '',
        role_id: '', // 角色id
        add_permissions: [], // 新增的权限
        del_permissions: [] // 删除的权限

      },
      // 选择角色的合集
      tissuearr: [],
      // 分配时的角色列表
      roteListeData: [],
      // 组织列表
      tableData: [

      ],
      // 点击重新分配是的id
      roleIndex: '',
      // 重新分配权限对话框
      fenpeiVisible: false,
      // 修改是显示的层级
      parenteidt: []
    }
  },
  created() {
    this.getParent()
    this.getUserList()
  },
  methods: {
    async getUserList() {
      const { data: { data: { list }}} = await GetPersonnelList({ pagesize: '10000' })
      this.chargerOptions = list
      console.log(this.chargerOptions)
    },
    // 获取列表
    async getParent() {
      // 发起请求
      const { data } = await GetAssociate()
      // 调用弹窗提示
      this.tishi(data.code, '获取组织列表')
      // 给添加修改的级联选择器提供数据
      this.parentoptions = data.data

      // 调用递归给列表添加数据
      this.setdata(this.parentoptions)
      console.log(this.tableData)
    },
    // 递归获取的表push到一个数组中
    setdata(datas) {
      // forech循环数组
      datas.forEach((v) => {
        // 把每一项添加到列表数组中
        this.tableData.push(v)

        // 判断是否有子项
        if (v.childs && v.childs.length > 0) {
          // 如果有递归
          this.setdata(v.childs)
        } else {
          // 如果没有吧子项设置undefined，便于级联选择器使用
          v.childs = undefined
        }
      })
    },
    // 监听添加修改关闭窗口重置内容
    addRolesClose() {
      this.$refs.addeFormRef.resetFields()
    },
    // 添加角色按钮
    addRolesName() {
      this.dialogVisible = true
    },
    // 级联选择器选中节点
    handleChange(num) {
      console.log(num)
      // 调用转换方法
      this.transitionString(num, 'addeForm')
    },
    // 修改时级联选中节点
    eidthandleChange(num) {
      // 调用转换方法
      this.transitionString(num, 'editTissueForm')
    },
    // 选中节点转换成字符串
    transitionString(valeu, name) {
      // value是级联选择的值name是要修改的对象名
      valeu.forEach(item => {
        this[name].parent_id = item
      })

      // console.log(num)
    },
    // 添加请求
    async addOrganization() {
      // 前置验证
      this.$refs.addeFormRef.validate(async valid => {
        if (!valid) return false
        console.log(this.addeForm)
        // 发起请求
        const { data } = await AddAssociate(this.addeForm)
        // 调用提示
        this.tishi(data.code, '添加组织')
        // 清空
        this.$refs.addeFormRef.resetFields()
        // 关闭
        this.dialogVisible = false
      })

      // this.getParent()
    },
    // 删除的方法
    async deleRolesUser(id) {
      const deliRight = await this.$confirm('此操作将永久删除该组织, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (deliRight !== 'confirm') return this.$message.info('已取消删除')
      const { data } = await DelAssociate(id)
      console.log(data)
      this.tishi(data.code, '删除角色')
    },

    // 点击修改数据
    async finOrganization(rightid) {
      const { data } = await OneAssociate({ id: rightid, need_roles: 1 })
      console.log(data)
      this.editTissueForm = data.data

      this.editTissueForm.parent_id = JSON.parse(data.data.parent_id)
      this.editTissueForm.type = this.editTissueForm.type + ''
      this.editlogVisible = true
      console.log(this.editTissueForm)
    },
    // 确认编辑
    editRoles() {
      this.$refs.addeFormRef.validate(async valid => {
        if (!valid) return false
        const { data } = await EditAssociate(this.editTissueForm)
        this.tishi(data.code, '修改组织')
        this.$refs.addeFormRef.resetFields()
        this.editlogVisible = false
        // 获取列表
      })
    },

    // 获取权限列表
    async getQu() {
      const { data } = await GetPermission()
      console.log(data)
    },

    // 分配权限
    async showSetRighDialog(role) {
      // 把用户id添加
      this.tissue.id = role.id
      // 发起请求获取需要设置的角色列表
      const { data: res } = await GetParent()
      if (res.code !== 200) return this.$message.error('获取角色列表失败')
      // 把角色列表保存
      this.roleOptions = res.data
      console.log(res)
      // 获取已经添加的角色列表
      const { data: red } = await OneAssociate({ id: role.id, need_roles: 1 })
      this.roteListeData = red.data.roles
      console.log(this.roteListeData)
      // red.data.roles.forEach(item=>{
      //   this.roteListeData.push(item)
      // })

      // 发起请求获取权限
      const { data } = await GetLimits()
      if (data.code !== 200) return this.$message.error('获取权限列表失败')
      // 获取权限列表数据保存
      this.rightlist = data.data
      console.log(this.rightlist)
      this.setRighDialogVisible = true
    },
    // 选中角色后的操作
    async roleChange(num) {
      // 判断现在数组中有没有已经渲染的值
      // 有的话不执行，没有在添加
      const ere = this.roteListeData.some(item => {
        if (item.id === num[0]) {
          return true
        }
      })
      if (ere) {
        alert('角色已存在')
      } else {
        // 获取角色信息
        const { data: res } = await GetRoleone(num)
        console.log(res)
        res.data.role_id = res.data.id
        // 添加到数组中
        this.roteListeData.push(res.data)
        console.log(this.roteListeData)
      }
    },
    // 全选选后数据
    checAall(val) {
      console.log(val)

      val.forEach(item => {
        this.tissue.role_id = item.role_id
        this.tissue.add_permissions = item.add_permissions
        this.tissue.del_permissions = item.del_permissions
        const objs = JSON.parse(JSON.stringify(this.tissue))
        this.tissuearr.push(objs)
        this.tissue.role_id = ''
      })
      console.log(this.tissuearr)
    },
    // 单选选后数据
    handleSelectionChange(val, row) {
      console.log(val)
      // 判断长度来确定是选择还是取消
      if (val.length > 0) {
        // 把数组转成字符串
        val.forEach(item => {
          this.tissue.role_id = item.role_id
        })
        // 深拷贝赋值
        const objs = JSON.parse(JSON.stringify(this.tissue))
        this.tissuearr.push(objs)
        console.log(this.tissuearr)
      } else {
        // 取消时的id
        const row_id = row.id
        // 遍历删除取消的选项
        this.tissuearr.forEach((item, i) => {
          if (item.role_id === row_id) {
            this.tissuearr.splice(i, 1)
          }
        })
      }
      this.tissue.role_id = ''
      this.tissue.add_permissions = []
      this.tissue.del_permissions = []
    },

    // 点击确定按钮分配角色
    allotRights() {
      console.log(this.tissuearr)
      this.tissuearr.forEach(async item => {
        // 分配角色的请求
        const { data } = await AddDistributionRole(item)
        console.log(data)
      })
      this.roteListeData = []
      this.setRighDialogVisible = false
    },
    // 点击修改权限
    roteListSetRighDialog(role) {
      console.log('*******')
      console.log(role)
      this.roleIndex = role.$index
      console.log(role.row.permissions)
      // 设置默认选中项
      this.defKeys = role.row.permissions.map(item => {
        return item.id
      })

      // 递归赋值展示

      this.getleafKeys(role.row, this.defKeysitem)
      console.log(this.defKeysitem)
      this.fenpeiVisible = true
    },
    // 通过递归形式获取角色的三级权限节点ID并保存到defKeysitem
    // 如果当前node节点不包括children属性，则是三级节点
    getleafKeys(node, arr) {
      if (!node.children) {
        return arr.push(node.id)
      }
      node.children.forEach(item =>
        this.getleafKeys(item, arr)
      )
    },
    // 选择新增或删除权限后
    async reassignment() {
      // 获取已选中的权限
      const ree1 = [...this.$refs.terrRef.getCheckedKeys()]
      console.log(ree1)
      // 处理数组得到正确的数组格式

      const receivearr = ree1.filter((item) => {
        return item !== undefined
      })
      // 增加时的差值
      const intersection1 = receivearr.filter((item) => !this.defKeys.includes(item))
      this.tissue.add_permissions = intersection1

      // eslint-disable-next-line no-undef
      this.roteListeData[this.roleIndex]['add_permissions'] = intersection1
      console.log(this.roteListeData[this.roleIndex]
      )
      // 减少时的差值
      const intersection2 = this.defKeys.filter((item) => !receivearr.includes(item))
      this.tissue.del_permissions = intersection2
      this.roteListeData[this.roleIndex]['del_permissions'] = intersection2
      console.log(this.tissue)
      this.fenpeiVisible = false
    },
    // 取消修改权限对话框
    quxiaoxiugaiquanxian() {
      this.fenpeiVisible = false
    },
    // 关闭分配角色对话框
    setRightDialogClosed() {
      this.defKeys = []
      this.roteListeData = []
      this.tissue.role_id = ''
      this.tissue.id = ''
    },
    // 关闭修改权限对话框
    endiRightDialogClosed() {
      this.defKeys = []
      this.defKeysitem = []
      this.tissue.role_id = ''
      // this.$refs.terrRef.setCheckedKeys([])
    },
    // 提示
    tishi(code, text) {
      if (code === 200) {
        this.$message.success(text + '成功')
        // 判断是不是获取角色列表
        if (text !== '获取组织列表') {
          // 获取列表
          this.golist()
        }
      } else {
        this.$message.error(text + '失败')
        return false
      }
    },
    // 添加修改删除的集成获取列表
    golist() {
      this.tableData = []
      this.getParent()
    }
  }
}
</script>
<style lang="less" scoped>
.el-button {
  padding: 8px 11px !important;
}
.el-tag {
  margin: 7px;
}
.bdtop {
  border-top: 1px solid #eee;
}
.bbottom {
  border-bottom: 1px solid #eee;
}
.vcenter {
  display: flex;
  align-items: center;
}
</style>
