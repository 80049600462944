<template>
  <div class="layout_home">
    <div class="home_hader">
      <!-- logo -->
      <div class="logo">智慧有方</div>
      <!-- 模块 -->
      <div>
        <el-dropdown @command="divsionfun">
          <i class="mode el-icon-s-grid"></i>
          <el-dropdown-menu slot="dropdown">
            <div class="plate" v-if="xianshi">
              <el-dropdown-item v-for="item in divisionModule" :key="item.id" :command="item.id">
                <i :class="item.icon"></i>
                {{ item.name }}
              </el-dropdown-item>
            </div>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- 头像 -->
        <el-dropdown class="avatar" @command="outLog">
          <el-avatar src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"></el-avatar>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="1">个人信息</el-dropdown-item>
            <el-dropdown-item command="2">切换角色</el-dropdown-item>
            <el-dropdown-item command="3">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="home_concent">
      <div class="concent_left">
        <el-menu
          style="overflow: hidden"
          :default-active="$route.path"
          class="el-menu-vertical-demo"
          background-color="#545c64"
          text-color="#fff"
          active-text-color="#ffd04b"
          router
        >
          <component
            class="menu-item"
            v-for="value in handleNavigationList"
            :key="value.index"
            :index="value.router"
            :is="value.children && value.children.length > 0 ? 'el-submenu' : 'el-menu-item'"
          >
            <template slot="title">
              <i :class="[value.icon]"></i>
              <span>{{ value.name }}</span>
            </template>
            <template v-if="value.children && value.children.length > 0">
              <el-menu-item class="menu-item" v-for="(v, i) in value.children" :key="v.name + i" :index="v.router">
                <i :class="[v.icon]"></i>
                <span slot="title">{{ v.name }}</span>
              </el-menu-item>
            </template>
          </component>

          <!--          <el-submenu index="1">-->
          <!--            <template slot="title">-->
          <!--              <i class="el-icon-location"></i>-->
          <!--              <span>导航一</span>-->
          <!--            </template>-->
          <!--            <el-menu-item-group>-->
          <!--              <el-menu-item index="1-1">选项1</el-menu-item>-->
          <!--              <el-menu-item index="1-2">选项2</el-menu-item>-->
          <!--            </el-menu-item-group>-->
          <!--          </el-submenu>-->
          <!--                    <el-menu-item :index='item.router' v-for='item in handleNavigationList' :key='item.index'>-->
          <!--                      <i :class='item.icon'></i>-->
          <!--                      <span slot='title'>{{ item.name }}</span>-->

          <!--                    </el-menu-item>-->
        </el-menu>
      </div>
      <div class="concent_right">
        <el-main>
          <!-- 路由占位符 -->
          <router-view></router-view>
        </el-main>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { getLocal } from '../../utils/local'

export default {
  data() {
    return {
      gai: '15753109663',
      wangfeng: '18561321433',
      cao: '17854260201',
      huang: '17564009489',
      na: '18563981252',
      shan: '18854213813',
      duanxintong: '13361088876',
      wanglu: '17852023889',
      xianshi: false,
      identification: 2, // 左侧列表显示的内容
      circleUrl: '@/assets/logo.png',
      // 左侧导航菜单
      navigationList: [
        {
          dent: 1,
          name: '员工管理',
          router: 'personnel-list',
          icon: 'el-icon-document',
          children: [{ name: '员工列表', router: '/personnel-list', icon: 'el-icon-document' }]
        },
        { dent: 1, name: '部门管理', router: 'divisional', icon: 'el-icon-menu', children: [{ name: '部门管理', router: '/divisional', icon: 'el-icon-menu' }] },
        {
          dent: 1,
          name: '角色管理',
          router: 'personnel-role',
          icon: 'el-icon-s-custom',
          children: [{ name: '角色管理', router: '/personnel-role', icon: 'el-icon-setting' }]
        },
        {
          dent: 1,
          name: '组织架构',
          router: 'organization',
          icon: 'el-icon-s-operation',
          children: [
            { name: '架构管理', router: '/organization', icon: 'el-icon-guide' },
            { name: '组织管理', router: '/relevanceOrg', icon: 'el-icon-s-ticket' },
            { name: '岗位管理', router: '/position', icon: 'el-icon-s-check' },
            // { name: '职级管理', router: '/rank', icon: 'el-icon-thumb' },
            { name: '员工库管理', router: '/employeeSet', icon: 'el-icon-s-check' }
          ]
        },
        {
          dent: 1,
          name: '权限管理',
          router: 'jurisdiction',
          icon: 'el-icon-setting',
          children: [
            { name: '权限信息', router: '/jurisdiction', icon: 'el-icon-s-order' },
            { name: '权限分配', router: '/allocation', icon: 'el-icon-s-check' }
          ]
        },
        { dent: 2, name: '首页', router: 'carinfo', icon: 'el-icon-s-order' },
        {
          dent: 2,
          name: '车辆列表',
          router: 'vehicle',
          icon: 'el-icon-truck',
          children: [
            { name: '车辆列表', router: '/carlist', icon: 'el-icon-s-order' },
            // { name: '车牌列表', router: 'plate', icon: 'el-icon-s-order' },
            { name: '车辆使用类型', router: '/cartype', icon: 'el-icon-s-order' },
            { name: '车辆所有权', router: '/everyone', icon: 'el-icon-s-order' }
          ]
        },
        {
          dent: 2,
          name: '保险列表',
          router: 'insurance',
          icon: 'el-icon-sugar',
          children: [
            { name: '保险信息', router: '/addinsu', icon: 'el-icon-s-order' },
            { name: '保险公司', router: '/company', icon: 'el-icon-s-order' },
            { name: '保险类型', router: '/insuranceclass', icon: 'el-icon-s-order' },
            { name: '出险登记', router: '/insureregister', icon: 'el-icon-s-order' }
          ]
        },
        {
          dent: 2,
          name: '年审信息',
          router: 'annual',
          icon: 'el-icon-bell',
          children: [
            { name: '年审信息', router: '/annual', icon: 'el-icon-s-order' }
            // { name: '年审信息录入', router: 'annualru', icon: 'el-icon-s-order' },
          ]
        },
        {
          dent: 2,
          name: '车辆维修',
          router: 'maintain',
          icon: 'el-icon-setting',
          children: [
            { name: '维修信息', router: '/maintain', icon: 'el-icon-s-order' },
            { name: '维修结算', router: '/close', icon: 'el-icon-s-order' },
            { name: '维修单位', router: '/repair', icon: 'el-icon-s-order' }
          ]
        },
        {
          dent: 2,
          name: '加油信息',
          router: 'gasoline',
          icon: 'el-icon-s-release',
          children: [
            { name: '加油记录', router: '/comerecord', icon: 'el-icon-s-order' },
            { name: '加油审核', router: '/comeonaudit', icon: 'el-icon-s-order' },
            { name: '用油分析', router: '/analyse', icon: 'el-icon-s-order' }
          ]
        },
        // {
        //   dent: 2,
        //   name: '费用列表',
        //   router: 'cost',
        //   icon: 'el-icon-bank-card',
        //   children: [
        //     { name: '租赁车辆', router: 'lease', icon: 'el-icon-s-order' }
        //     // { name: '加油记录', router: 'comerecord', icon: 'el-icon-s-order' }
        //   ]
        // },
        {
          dent: 2,
          name: '车辆调动',
          router: 'dispatch',
          icon: 'el-icon-aim',
          children: [
            { name: '调动申请', router: '/dispatch', icon: 'el-icon-finished' },
            { name: '调动审核', router: '/dispshen', icon: 'el-icon-s-order' },
            { name: '调动记录', router: '/dispjilu', icon: 'el-icon-s-order' }
          ]
        },

        {
          dent: 2,
          name: '车辆收转运',
          router: 'collection',
          icon: 'el-icon-sugar',
          children: [{ name: '车辆管理', router: 'manager', icon: 'el-icon-s-order' }]
        },
        {
          dent: 4,
          name: '合同管理',
          router: 'client',
          icon: 'el-icon-collection',
          children: [
            { name: '客户信息', router: '/client', icon: 'el-icon-notebook-1' },
            { name: '供应商信息', router: '/slo', icon: 'el-icon-files' }
          ]
        },
        // {
        //   dent: 6,
        //   name: '售后管理',
        //   router: 'aftersale',
        //   icon: 'iconfont icon-shouhou',
        //   children: [
        //     { name: '提交记录审核', router: '/aftersale', icon: 'el-icon-view' },
        //     { name: '纸质票据审核', router: '/paperBills', icon: 'el-icon-view' },
        //     { name: '报销记录', router: '/reimbursementRecord', icon: 'el-icon-view' },
        //     { name: '报销汇总', router: '/reimbursementCollect', icon: 'el-icon-view' }
        //   ]
        // },
        { dent: 3, name: '报销记录审核', router: 'aftersale', icon: 'el-icon-view' },
        { dent: 3, name: '报销票据审核', router: 'paperBills', icon: 'el-icon-s-order' },
        { dent: 3, name: '审核历史', router: 'reimbursementRecord', icon: 'el-icon-s-order' },
        { dent: 3, name: '报销汇总', router: 'reimbursementCollect', icon: 'el-icon-s-order' },

        {
          dent: 5,
          name: '仓储管理',
          router: 'store',
          icon: 'el-icon-menu',
          children: [
            { name: '仓库信息', router: '/store', icon: 'el-icon-present' },
            { name: '配件信息', router: '/mountings', icon: 'el-icon-s-help' },
            { name: '配件类型', router: '/accestype', icon: 'el-icon-s-tools' },
            { name: '公共库存状态', router: '/inventory', icon: 'el-icon-discount' },
            { name: '库存预警', router: '/kuwarning', icon: 'el-icon-s-opportunity' },
            { name: '配件调配', router: '/deploy', icon: 'el-icon-paperclip' },
            { name: '批次管理', router: '/storebatch', icon: 'el-icon-s-data' },
            { name: '入库记录', router: '/rulist', icon: 'el-icon-open' },
            { name: '出库记录', router: '/chulist', icon: 'el-icon-connection' }
          ]
        },
        {
          dent: 5,
          name: '采购管理',
          router: 'purchasing',
          icon: 'el-icon-suitcase-1',
          children: [
            { name: '对公采购申请', router: '/purchasing', icon: 'el-icon-burger' },
            { name: '对公采购审批', router: '/purshensing', icon: 'el-icon-remove' },
            { name: '对公采购单', router: '/buyer', icon: 'el-icon-s-goods' },
            { name: '供应商业务管理', router: '/business', icon: 'el-icon-brush' },
            { name: '采购合同管理', router: '/hereview', icon: 'el-icon-document-remove' }
          ]
        },
        {
          dent: 5,
          name: '售后订单管理',
          router: 'order',
          icon: 'el-icon-notebook-2',
          children: [
            { name: '售后订单列表', router: '/order', icon: 'el-icon-guide' },
            { name: '维修业务列表', router: '/service', icon: 'el-icon-s-management' },
            { name: '维修单审批记录', router: '/repairbill', icon: 'el-icon-document-remove' },
            { name: '用料单审批记录', router: '/billof', icon: 'el-icon-document-delete' }
          ]
        },
        {
          dent: 5,
          name: '合同管理',
          router: 'client',
          icon: 'el-icon-collection',
          children: [
            { name: '客户信息', router: '/client', icon: 'el-icon-notebook-1' },
            { name: '供应商信息', router: '/slo', icon: 'el-icon-files' }
          ]
        },
        {
          dent: 6,
          name: '配件列表',
          router: 'parts',
          icon: 'el-icon-setting',
          children: [
            { name: '配件列表', router: '/parts', icon: 'el-icon-s-order' },
            { name: '批次列表', router: '/batch', icon: 'el-icon-s-order' },
            { name: '产品种类', router: '/productcategory', icon: 'el-icon-s-order' },
            { name: '适配型号', router: '/adaptivemodel', icon: 'el-icon-s-order' },
            { name: '供应商列表', router: '/supplier', icon: 'el-icon-s-order' },
            { name: '品牌列表', router: '/brand', icon: 'el-icon-s-order' },
            { name: '图纸列表', router: '/drawing', icon: 'el-icon-s-order' }
          ]
        }
      ],
      // 模块菜单
      divisionModule: [
        { id: 1, name: '人事管理', icon: 'iconfont icon-renshifuwu' },
        { id: 2, name: '车辆管理', icon: 'iconfont icon-cheliangxinxi' },
        { id: 3, name: '财务管理', icon: 'iconfont icon-caiwu' },
        { id: 4, name: '合同管理', icon: 'iconfont icon-hetongguanli' },
        { id: 5, name: '仓储管理', icon: 'iconfont icon-chengpincangku' },
        { id: 6, name: '售后管理', icon: 'iconfont icon-shouhou' }
      ],
      navigationLists: [
        {
          dent: 2,
          name: '加油信息',
          router: 'gasoline',
          icon: 'el-icon-s-release',
          children: [
            { name: '加油记录', router: '/comerecord', icon: 'el-icon-s-order' },
            { name: '加油审核', router: '/comeonaudit', icon: 'el-icon-s-order' },
            { name: '用油分析', router: '/analyse', icon: 'el-icon-s-order' }
          ]
        },
        {
          dent: 2,
          name: '车辆调动',
          router: 'dispatch',
          icon: 'el-icon-aim',
          children: [
            { name: '调动申请', router: '/dispatch', icon: 'el-icon-finished' },
            { name: '调动审核', router: '/dispshen', icon: 'el-icon-s-order' },
            { name: '调动记录', router: '/dispjilu', icon: 'el-icon-s-order' }
          ]
        },

        {
          dent: 2,
          name: '车辆收转运',
          router: 'collection',
          icon: 'el-icon-sugar',
          children: [{ name: '车辆管理', router: '/manager', icon: 'el-icon-s-order' }]
        },
        {
          dent: 2,
          name: '保险列表',
          router: 'insurance',
          icon: 'el-icon-sugar',
          children: [
            // { name: '保险信息', router: 'addinsu', icon: 'el-icon-s-order' },
            // { name: '保险公司', router: 'company', icon: 'el-icon-s-order' },
            // { name: '保险类型', router: 'insuranceclass', icon: 'el-icon-s-order' },
            { name: '出险登记', router: '/insureregister', icon: 'el-icon-s-order' }
          ]
        }
      ]
    }
  },
  methods: {
    ...mapMutations(['LOGIN_OUT']),
    outLog(e) {
      // console.log( this.LOGIN_OUT())
      // this.LOGIN_OUT()
      switch (e) {
        // 个人中心
        case '1':
          break
        // 切换角色
        case '2':
          break
        // 退出
        case '3':
          this.LOGIN_OUT()
          this.$router.push('/login')
          break
      }
    },
    // 点击模块图标改变导航栏的内容
    divsionfun(id) {
      sessionStorage.setItem('identification', id)
      // 应该是这个方法  就是这里
      this.identification = id
      const newsou = this.navigationList.find(item => {
        return item.dent == id
      })
      // console.log(newsou, 'newsou')
      this.$router.push(`/${newsou.router}`)
    }
  },
  mounted() {
    // 解决刷新页面总是跳回首页问题
    // 刷新页面前先存id，页面加载后拿缓存id调接口
    if (sessionStorage.getItem('identification')) {
      this.identification = Number(sessionStorage.getItem('identification'))
      this.divsionfun(this.identification)
    } else {
      this.divsionfun(this.identification)
    }
    // 刷新页面前先存路由，页面加载后直接跳路由
    if (sessionStorage.getItem('routerPath')) {
      this.$router.push(`${sessionStorage.getItem('routerPath')}`)
    } else {
      this.divsionfun(this.identification)
    }
    window.addEventListener('beforeunload', event => {
      console.log('存set')
      sessionStorage.setItem('identification', this.identification)
      sessionStorage.setItem('routerPath', this.$route.path)
    })
  },
  created() {
    this.identification = Number(sessionStorage.getItem('identification') ? sessionStorage.getItem('identification') : 2)
    let caaa = localStorage.getItem('ming')
    console.log(caaa)
    if (caaa == this.wangfeng) {
      this.xianshi = true
    } else if (caaa == this.cao) {
      this.xianshi = true
    } else if (caaa == this.huang) {
      this.xianshi = true
    } else if (caaa == this.gai) {
      this.xianshi = true
    } else if (caaa == this.na) {
      this.xianshi = true
    } else if (caaa == this.shan) {
      this.xianshi = true
    } else if (caaa == this.duanxintong) {
      this.xianshi = true
    } else if (caaa == this.wanglu) {
      this.xianshi = true
    } else {
      this.xianshi = false
    }
  },
  computed: {
    // ...mapState(['isSidebarNavCollapse'])
    handleNavigationList() {
      if (this.xianshi) {
        return this.navigationList.filter(item => {
          return item.dent === this.identification
        })
      } else {
        return this.navigationLists.filter(item => {
          return item.dent === this.identification
        })
      }
    }
  },
  components: {
    // sidebarNav
    // mainContent
  }
}
</script>

<style lang="less" scoped>
.layout_home {
  display: flex;
  flex-direction: column;
  line-height: 50px;

  .home_hader {
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    height: 50px;
    background-color: rgb(84, 92, 100);
    color: rgb(242, 245, 247);

    .avatar {
      float: right;
      margin-top: 5px;
    }

    .mode {
      color: white;
      font-size: 35px;
      line-height: 50px;
      margin-right: 15px;
    }
  }

  .home_concent {
    display: flex;
    height: 100vh;

    .concent_left {
      height: 100%;
      background-color: rgb(84, 92, 100);
      width: 200px;
      min-width: 200px;
      overflow: hidden;
      overflow: scroll;
    }

    .concent_right {
      width: 100%;
      height: 100%;
      .el-main {
        height: 100%;
      }
    }
  }

  .concent_left::-webkit-scrollbar {
    display: none !important;
  }
}

.plate {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 300px;

  li {
    display: flex;
    flex-direction: column;
    text-align: center;

    i {
      color: #e58f0e;
      font-size: 25px;
    }
  }
}
</style>
